import DatadogActionItems from './DatadogActionItems.react';

export default {
  name: 'datadog-action-items',
  components: {
    DatadogActionItems,
  },
  methods: {
    router() {
      return this.$router;
    },
    route() {
      return this.$route;
    },
  },
};
