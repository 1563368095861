import React, { useEffect, useState, useMemo } from 'react';

import { LayoutReact, Card, Breadcrumbs } from '@fairwindsops/ui-components';
import ActionItemCard from './ActionItems/ActionItemCard.react';
import CardDropdown from '~reactComponents/CardDropdown/CardDropdown.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import {
  ORG_DASHBOARD,
  ACTION_ITEMS_CLUSTER,
  CLUSTER_OVERVIEW,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import { actionItemOptions } from './ActionItems/ActionItems.config.react';

import { ActionItem, DatadogActionItemsProps } from './ActionItems/ActionItems.types.react';
import { OptionType, Membership } from '~utils/global.types.react';

import { sortAscending } from '~utils/helpers';
import { handlePageChange } from '~utils/global.helpers.react';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~utils/logger';

import './style.scss';

const DatadogActionItems = ({ router, route }: DatadogActionItemsProps) => {
  const org = route?.params?.org;
  const baseURL = `/v0/organizations/${org}`;
  const [memberships, setMemberships] = useState<Membership[]>([]);
  const [actionItemsBySeverity, setActionItemsBySeverity] = useState<ActionItem[]>([]);
  const [assignedActionItems, setAssignedActionItems] = useState<ActionItem[]>([]);
  const [selectedActionItemOption, setSelectedActionItemOption] = useState<OptionType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const requests = [
        sendRequest('GET', `${baseURL}/memberships`, { cache: true }, null),
        fetchActionItems(),
      ];

      try {
        const [memberships] = await Promise.allSettled(requests);

        if (memberships.status === 'fulfilled') {
          setMemberships(memberships.value);
        }
      } catch (e) {
        logger.logError('error_retrieving_datadog_memberships', e);
      }
      setIsLoading(false);
    };

    getData();
  }, []);

  useEffect(() => {
    displayNewestActionItems();
  }, [actionItemsBySeverity]);

  const actionItems = useMemo(() => {
    if (actionItemsBySeverity.length || assignedActionItems.length) {
      if (selectedActionItemOption?.value === 'My Action Items' && actionItemsBySeverity.length) {
        return assignedActionItems.slice(0, 2);
      }

      return actionItemsBySeverity.slice(0, 2);
    }

    return [];
  }, [selectedActionItemOption, assignedActionItems, actionItemsBySeverity]);

  const membershipOptions = useMemo(() => {
    if (memberships?.length) {
      const members = memberships
        .map((membership) => ({
          label: membership.Email,
          value: membership.Email,
        }))
        .sort((a, b) => sortAscending(a.value, b.value));

      const none = [{ label: strings.general.None, value: null }];

      return [...none, ...members];
    }

    return [];
  }, [memberships]);

  const fetchActionItems = async () => {
    const requests = [];

    requests.push(
      sendRequest(
        'GET',
        `${baseURL}/action-items?orderBy=FirstSeen.desc,Severity.desc&Resolution=None&Cluster=${route?.params?.cluster}&limit=2`,
        {},
        null,
      ),
      sendRequest(
        'GET',
        `${baseURL}/action-items/assigned?limit=2&Cluster=${route?.params?.cluster}`,
        {},
        null,
      ),
    );

    try {
      const [actionItemsBySeverity, assignedActionItems] = await Promise.allSettled(requests);

      if (actionItemsBySeverity.status === 'fulfilled') {
        setActionItemsBySeverity(actionItemsBySeverity.value);
      }
      if (assignedActionItems.status === 'fulfilled') {
        setAssignedActionItems(assignedActionItems.value);
      }
    } catch (e) {
      logger.logError('error_retrieving_datadog_action_items', e);
    }
  };

  const displayNewestActionItems = () => {
    if (!assignedActionItems.length && actionItemsBySeverity.length) {
      setSelectedActionItemOption({
        label: strings.datadog.newestActionItems,
        value: 'Newest Action Items',
      });
    }
  };

  const actionsAfterUpdates = (passedIndex: number) => {
    setAssignedActionItems((prevState) =>
      prevState.filter((actionItem, index) => index !== passedIndex),
    );
    displayNewestActionItems();
    fetchActionItems();
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: CLUSTER_OVERVIEW,
      label: route?.params?.cluster,
      href: `/orgs/${org}/clusters/${route?.params?.cluster}`,
    },
    {
      id: 'last',
      label: strings.datadog.datadogActionItems,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact className="datadog__overview-my-action-items-card">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => handlePageChange(router, route)}
      />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Card>
          <Card.Header className="datadog__header">
            <div>
              {!window?.datadog ? (
                <CardDropdown
                  options={actionItemOptions}
                  value={selectedActionItemOption}
                  onChange={setSelectedActionItemOption}
                  label="select datadog action items filter"
                />
              ) : (
                <h1>{strings.datadog.newestActionItems}</h1>
              )}
            </div>
            <a
              className="action-items-link"
              href={
                router().resolve({
                  name: ACTION_ITEMS_CLUSTER,
                  params: { org },
                  query: { cluster: route?.params?.cluster },
                }).href
              }
              target={window?.datadog ? '_blank' : strings.noTranslate.self}
            >
              {strings.general.seeAll}
            </a>
          </Card.Header>
          <Card.Body>
            {actionItems.length > 0 ? (
              <>
                {actionItems?.map((actionItem, index) => (
                  <ActionItemCard
                    actionItem={actionItem}
                    router={router}
                    route={route}
                    actionsAfterUpdates={actionsAfterUpdates}
                    index={index}
                    membershipOptions={membershipOptions}
                    key={`${actionItem.Title}-${index}`}
                  />
                ))}
              </>
            ) : (
              <div className="datadog__no-actionitems">{strings.datadog.noActionItems}</div>
            )}
          </Card.Body>
        </Card>
      )}
    </LayoutReact>
  );
};

export default DatadogActionItems;
