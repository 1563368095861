import React from 'react';
import { StylesConfig } from 'react-select';

import { OptionType } from '~utils/global.types.react';

import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

export const actionItemOptions = [
  { label: strings.datadog.myActionItems, value: 'My Action Items' },
  { label: strings.datadog.newestActionItems, value: 'Newest Action Items' },
];

export const customStyles: StylesConfig<OptionType, true> = {
  control: (provided) => ({
    ...provided,
    width: strings.noTranslate.eighteenREM,
    minHeight: '2rem',
    maxHeight: '4rem',
    height: '2rem',
    backgroundColor: strings.noTranslate.transparent,
    border: `${COLORS.BORDER.SOLID_TABLE}`,
    color: COLORS.CORE.DARK_GRAY,
    overflow: 'hidden',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    marginBottom: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: strings.noTranslate.costsPadding,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: strings.noTranslate.smallBottomPadding,
  }),
  menu: (provided) => ({
    ...provided,
    maxWidth: strings.noTranslate.eighteenREM,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
  group: (provided) => ({
    ...provided,
    height: '15rem',
    overflow: 'scroll',
    marginRight: '0.25rem',
  }),
};
