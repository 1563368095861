import React, { SetStateAction } from 'react';
import Select from 'react-select';

import { OptionType } from '~utils/global.types.react';

import './CardDropdown.react.scss';

type CardDropdownProps = {
  options: OptionType[];
  value: OptionType;
  onChange: React.Dispatch<SetStateAction<OptionType>>;
  label: string;
};

const CardDropdown = ({ options, value, onChange, label }: CardDropdownProps) => {
  return (
    <div className="card-dropdown__date-select-container">
      <Select
        aria-label={label}
        className="card-dropdown__date-select timeline"
        classNamePrefix="card-dropdown__date-select"
        isSearchable={false}
        options={options}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default CardDropdown;
