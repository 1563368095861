import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { marked } from 'marked';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import PillBadge from '~reactComponents/PillBadge/PillBadge.react';

import { ACTION_ITEMS_CLUSTER } from '~reactComponents/NavigationReact/Navigation.config.react';

import { customStyles } from './ActionItems.config.react';

import { ActionItemCardProps, ActionItem } from './ActionItems.types.react';
import { OptionType } from '~utils/global.types.react';

import { ACTION_ITEM_RESOLUTIONS } from '~utils/constants';
import { getCurrentTimezone } from '~utils/global.helpers.react';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~utils/logger';
import { DropdownIndicator } from '~reactComponents/DropdownIndicator/DropdownIndicator.react';

dayjs.extend(timezone);

const ActionItemCard = ({
  actionItem,
  router,
  route,
  actionsAfterUpdates,
  index,
  membershipOptions,
}: ActionItemCardProps) => {
  const timezone = getCurrentTimezone();
  const org = route?.params?.org;
  const baseURL = `/v0/organizations/${org}`;
  const [textVisibility, setTextVisibility] = useState<Record<string, boolean>>({});
  const [selectedResolution, setSelectedResolution] = useState<OptionType | undefined>(() => {
    return actionItem.Resolution
      ? { label: actionItem.Resolution, value: actionItem.Resolution }
      : undefined;
  });
  const [selectedAssignee, setSelectedAssignee] = useState<OptionType | undefined>(() => {
    return actionItem.AssigneeEmail
      ? { label: actionItem.AssigneeEmail, value: actionItem.AssigneeEmail }
      : undefined;
  });

  const updateResolution = async (resolution: OptionType, actionItemID: number) => {
    let response;
    const payload = {
      IDs: [actionItemID],
      Resolution: resolution.value,
    };

    setSelectedResolution(resolution);
    // updating resolution doesn't show up in the action items table, why?
    try {
      response = await sendRequest(
        'PATCH',
        `${baseURL}/action-items/resolution/bulk`,
        { data: payload },
        null,
      );
    } catch (e) {
      logger.logError('error_updating_datadog_action_items_resolution', e);
    }

    if (response.Success) {
      actionsAfterUpdates(index);
    }
  };

  const updateAssignee = async (email: OptionType, actionItemID: number) => {
    let response;
    const payload = {
      IDs: [actionItemID],
      AssigneeEmail: email.value || null,
    };

    setSelectedAssignee(email);

    try {
      response = await sendRequest(
        'PATCH',
        `${baseURL}/action-items/assignee/bulk`,
        { data: payload },
        null,
      );
    } catch (e) {
      logger.logError('error_updating_datadog_action_items_assignee', e);
    }

    if (response.Success) {
      actionsAfterUpdates(index);
    }
  };

  const formatDate = (date) => {
    return timezone
      ? dayjs(date).tz(timezone).format(strings.dateFormats.monthAndTime)
      : dayjs(date).format(strings.dateFormats.monthAndTime);
  };

  const goToActionItem = (actionItem: ActionItem) => {
    return {
      name: ACTION_ITEMS_CLUSTER,
      params: {
        org,
      },
      query: {
        Cluster: route?.params?.cluster || '',
        ReportType: actionItem.ReportType,
        ResourceNamespace: actionItem.ResourceNamespace,
        ResourceName: actionItem.ResourceName,
        ResourceContainer: actionItem.ResourceContainer,
        Title: actionItem.Title,
      },
    };
  };

  const goToTarget = () => {
    return window?.datadog ? '_blank' : '';
  };

  const toggleTextVisibility = () => {
    if (textVisibility[index]) {
      setTextVisibility((prevState) => {
        const updated = { [index]: !prevState[index] };
        return { ...prevState, ...updated };
      });
    } else {
      setTextVisibility((prevState) => ({ ...prevState, [index]: true }));
    }
  };

  return (
    <div className="datadog__action-item-container">
      <div className="datadog__action-item-header">
        <div>
          <span>{actionItem.Title}</span>
        </div>
        <PillBadge severity={actionItem.Severity} />
      </div>
      <div className="datadog__action-item-subheader">
        <span className="datadog__action-item-date">{formatDate(actionItem.LastReportedAt)}</span>
        {/* if datadogIframe, add arrow? */}
        <a href={router().resolve(goToActionItem(actionItem)).href} target={goToTarget}>
          {strings.datadog.viewInTable}
        </a>
      </div>
      <div className="datadog__action-item-subheader">
        <div className="datadog__action-item-subheader-container">
          <span className="datadog__action-item-subheader__bold">{strings.efficiency.Kind}:</span>{' '}
          {actionItem.ResourceKind}
        </div>
        <div className="datadog__action-item-subheader-container">
          <span className="datadog__action-item-subheader__bold">
            {strings.repository.resourceName}:
          </span>{' '}
          {actionItem.ResourceName}
        </div>
        <div className="datadog__action-item-subheader-container">
          <span className="datadog__action-item-subheader__bold">
            {strings.automationRule.report}:
          </span>{' '}
          {actionItem.ReportType}
        </div>
      </div>
      <div className="datadog__action-item-content">
        <span className="datadog__action-item-content__title">
          {strings.datadog.actionItemManagement}
        </span>
        <div className="datadog__action-item-buttons">
          <Select
            className="custom--select_costs"
            classNamePrefix="custom--select_costs"
            aria-label="datadog action items resolution dropdown"
            styles={customStyles}
            value={selectedResolution}
            options={ACTION_ITEM_RESOLUTIONS}
            placeholder={strings.columnTitles.Resolution}
            isSearchable
            isClearable
            backspaceRemovesValue
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            components={{
              DropdownIndicator,
            }}
            onChange={(option) => updateResolution(option, actionItem.ID)}
          />
          <Select
            className="custom--select_costs"
            classNamePrefix="custom--select_costs"
            aria-label="datadog action items assignee dropdown"
            styles={customStyles}
            value={selectedAssignee}
            options={membershipOptions}
            placeholder={strings.columnTitles.Assignee}
            isSearchable
            isClearable
            backspaceRemovesValue
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            components={{
              DropdownIndicator,
            }}
            onChange={(option) => updateAssignee(option, actionItem.ID)}
          />
        </div>
        <span className="datadog__action-item-content__title">{strings.general.Description}</span>
        <div className="datadog__action-item-content__text">
          <div dangerouslySetInnerHTML={{ __html: marked(actionItem.Description) }} />
        </div>
      </div>
      <div className="datadog__action-item-content">
        <span className="datadog__action-item-content__title">{strings.policies.Remediation}</span>
        <div
          className={`datadog__action-item-content__text ${
            textVisibility[index] !== undefined
              ? textVisibility[index]
                ? 'text-expanded'
                : ''
              : ''
          }`}
        >
          <div dangerouslySetInnerHTML={{ __html: marked(actionItem.Remediation) }} />
        </div>
      </div>
      <Button
        variant="white"
        className="datadog__action-item-content__expand"
        onClick={toggleTextVisibility}
      >
        {strings.datadog.showMore}
      </Button>
    </div>
  );
};

export default ActionItemCard;
